import * as React from "react";

interface Props {
    className?: string,
    disabled?: boolean,
    name: string,
    onChange: React.ChangeEventHandler,
    placeholder?: string,
    type?: string,
    value: string,
    min?: number | string,
    max?: number | string,
    step?: number
}

const Input: React.FC<Props> = ({ className = "", disabled = false, name, onChange, placeholder = "", type = "text", value, min, max, step }) => <input
    className={`mb-4 p-1 border-2 w-full ${className}`}
    name={name}
    type={type}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    disabled={disabled}
    max={max}
    min={min}
    step={step}
/>

export default Input;