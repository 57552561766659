import * as React from "react"

interface FormDivProps {
    children: React.ReactNode,
    className?: ""
}

const FormDiv: React.FC<FormDivProps> = ({ children, className = "" }) => {
    return <form className={`py-4 w-full ${className}`}>{children}</form>
}

export default FormDiv