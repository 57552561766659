import * as React from "react";
import { baseTextClassName } from "../../data";

interface LabelProps {
    className?: string,
    label?: string,
    name: string
}

const Label: React.FC<LabelProps> = ({ className = "", label, name }) => <label
    className={`${baseTextClassName} ${className}`}
    htmlFor={name}
>
    {label || name.split(/(?=[A-Z])/).join(" ")}
</label>

export default Label;