import * as React from "react";
import IChildrenProps from "interfaces/IChildrenProps";

interface Props extends IChildrenProps {
    className?: string,
    disabled?: boolean,
    name: string,
    onChange: React.ChangeEventHandler,
    value: string
}

const Select: React.FC<Props> = ({ children, className = "", disabled = false, name, onChange, value }) => {
    return <select
        className={`mb-4 p-1 border-2 w-full ${className}`}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
    >
        <option value="">-</option>
        {children}
    </select>
}

export default Select;