import { WbsElementDataType } from ".";

class TreeNode {
  title: string;
  uuid: string;
  name: string;
  indices: Array<string>;
  children: Array<TreeNode>;

  constructor(title: string, name: string, uuid: string) {
    this.title = title;
    this.uuid = uuid;
    this.name = name;
    this.indices = name.split(' ').shift().split('.')
    this.children = []
  }
}

export default TreeNode;

export function insertIntoTree(currentNode: TreeNode, newNode: TreeNode): boolean {
    if (areIndicesEqual(currentNode.indices, newNode.indices.slice(0, newNode.indices.length - 1))) {
        currentNode.children.push(newNode)
        return true
    }

    for (let child of currentNode.children) {
        let result = insertIntoTree(child, newNode)
        if (result) break;
    }

    return false
}

export function createTree(data: Array<WbsElementDataType>): TreeNode {
    let sortedData = data.slice()
    sortedData.sort((a, b) => {
        let fa = a.element.toLowerCase(),
            fb = b.element.toLowerCase();

        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    })
    let root = new TreeNode(sortedData[0].title, sortedData[0].element, sortedData[0].uuid);
    sortedData.shift();

    for (let row of sortedData) {
        let newNode = new TreeNode(row.title, row.element, row.uuid)
        insertIntoTree(root, newNode)
    }

    return root
}

function areIndicesEqual(a: Array<string>, b: Array<string>): boolean {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}