import { IProviderAccount, Providers } from "@microsoft/mgt-react"

const getActiveAccount = (): IProviderAccount | null => {
    try {
        return Providers.globalProvider.getActiveAccount();
    } catch {
        return null;
    }
}

export default getActiveAccount;