import * as React from "react";
import { removeWorkItemBtnLabel } from "../../data";
// Features
import { WorkItemFormDataType } from "features/WorkItem";
import { WbsDataType, createTree } from "features/WBS";
// Components
import {
  FormFieldWrapper,
  FormInput,
  FormLabel,
  FormSelect,
  FormSelectOption,
  WbsFormElement,
} from ".";
import { MiniButton } from "components/Button";

interface WorkItemFieldProps {
  disabled?: boolean;
  index: number;
  label: string;
  name: string;
  onChange: (data: WorkItemFormDataType, index: number) => void;
  onRemove: (index: number) => void;
  values: WorkItemFormDataType;
  wbs: Array<WbsDataType>;
}

const WorkItemField: React.FC<WorkItemFieldProps> = ({
  disabled = false,
  index,
  label,
  name,
  onChange,
  onRemove,
  values,
  wbs,
}) => {
  const onInputChange = (field: string, value: any) => {
    const newValues: WorkItemFormDataType = {
      ...values,
      [field]: value,
    };
    onChange(newValues, index);
  };

  const onProjectChange = (event) => {
    // Reset WBS Element Look-Up ID
    setWbsElement("")
    // Set Project
    onInputChange("projectWbsListId", event.target.value);
  };

  const hoursWorkedOnChange = (event) => {
    onInputChange("hoursWorked", parseFloat(event.target.value));
  };

  const setWbsElement = (id: string): void => {
    onInputChange("wbsElementLookUpId", id);
  };

  return (
    <div className="mt-4">
      <FormFieldWrapper className="flex items-center content-center">
        <MiniButton
          align={"left"}
          variant={"cancel"}
          onClick={() => onRemove(index)}
        >
          {removeWorkItemBtnLabel}
        </MiniButton>
        <FormLabel label={label} name={name} />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormLabel label={"Hours Worked"} name={"hoursWorked"} />
        <FormInput
          disabled={disabled}
          type="number"
          name={"Hours Worked"}
          value={values.hoursWorked.toString()}
          onChange={hoursWorkedOnChange}
          min={0}
          max={24}
          step={0.01}
          placeholder={"0.00"}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormLabel label={"Project"} name={"projectLookUpId"} />
        <FormSelect
          disabled={disabled}
          name={"project"}
          value={values.projectWbsListId}
          onChange={onProjectChange}
        >
          {wbs.map((project, index2) => (
            <FormSelectOption
              key={`project-${index}-${index2}`}
              option={project.listId}
              label={project.projectName}
            />
          ))}
        </FormSelect>
      </FormFieldWrapper>
      <WbsElementSelector
        disabled={disabled}
        index={index}
        wbsData={wbs.find((project) => project.listId === values.projectWbsListId)}
        setWbsElement={setWbsElement}
        selectedLookUpId={values.wbsElementLookUpId}
      />
    </div>
  );
};

export default WorkItemField;

interface WbsElementSelectorProps {
  disabled: boolean;
  index: number;
  setWbsElement: (id: string) => void;
  selectedLookUpId: string;
  wbsData: WbsDataType;
}

export const WbsElementSelector: React.FC<WbsElementSelectorProps> = ({
  disabled,
  index,
  setWbsElement,
  selectedLookUpId,
  wbsData,
}) => {
  if (!wbsData) {
    return null;
  }

  const wbsRoot = createTree(wbsData.wbsEls);

  const label = selectedLookUpId
    ? `WBS Element: ${wbsData.wbsLookUpTable[selectedLookUpId].element}`
    : "WBS Element: None Selected";

  return (
    <FormFieldWrapper>
      <FormLabel label={label} name={"wbsElementLookUpId"} />
      <WbsFormElement
        disabled={disabled}
        index={0}
        node={wbsRoot}
        setWbsElement={setWbsElement}
        selectedLookUpId={selectedLookUpId}
      />
    </FormFieldWrapper>
  );
};