import { PayloadAction, createSlice } from "@reduxjs/toolkit"
// Wbs Element
import { WbsDataType } from "."

interface WbsState {
    list: Array<WbsDataType>
}

export const initialState: WbsState = {
    list: null
}

export const mergeWbs = (list: Array<WbsDataType>, payload: Array<WbsDataType>): Array<WbsDataType> => {
    let newList = [...list]
    payload.map(newWbs => {
        if (list.some(wbs=> wbs.listId === newWbs.listId)) {
            return
        }
        newList.push(newWbs)
    })
    return newList
}

export const wbsSlice = createSlice({
    name: 'wbs',
    initialState,
    reducers: {
        loadWbs: (state, action: PayloadAction<WbsDataType>) => {
            // Check if payload is null
            if (action.payload === null) {
                state.list = null
                return
            }
            // Check if list is null
            if (state.list === null) {
                state.list = [action.payload]
                return
            }
            // Insert Data
            state.list = mergeWbs(state.list, [action.payload])
        },
        addWbs: (state, action: PayloadAction<WbsDataType>) => {
            // Check if payload is null
            if (action.payload === null) {
                return
            }
            // Check if list is null
            else if (state.list === null) {
                state.list = [action.payload]
                return
            }
            // Insert Data
            state.list = mergeWbs(state.list, [action.payload])
        }
    }
})

export const { loadWbs, addWbs } = wbsSlice.actions
export const wbsReducer = wbsSlice.reducer;
export default wbsReducer;