import { createSlice, PayloadAction } from "@reduxjs/toolkit"
// User
import { UserDataType } from "."
import { ResourceGroupType } from "../../data";

export interface UserState {
    values: UserDataType,
    resourceGroup: ResourceGroupType
}

export const initialState: UserState = {
    values: null,
    resourceGroup: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loadUser: (state, action: PayloadAction<UserDataType>) => {
            if (action.payload === null) {
                state.values = null
                return
            }
            state.values = {
                id: action.payload.id,
                displayName: action.payload.displayName,
                email: action.payload.email
            }
        },
        loadResourceGroup: (state, action: PayloadAction<ResourceGroupType>) => {
            state.resourceGroup = action.payload;
        }
    }
});

export const { loadUser, loadResourceGroup } = userSlice.actions;
export const userReducer = userSlice.reducer;
export default userReducer;