import * as React from "react";
import { TableBodyItem, TableRow, dataRowClass } from "components/Table"
import { WbsOption, WorkItemChangesDictType, WorkItemChangesType, noEntriesText } from "containers/DataTable";
import { WorkItemDataType } from "features/WorkItem";
import { WbsDataType, WbsElementDataType, getWbsDataByList } from "features/WBS";
import FormTableRow from "containers/FormTableRow";
import { Client } from "@microsoft/microsoft-graph-client";
import { ResourceGroupType } from "../../data";

// Get Work Item Table Rows
const getWorkItemChangesFromDict = (workItemChangesDict: WorkItemChangesDictType, index: number): WorkItemChangesType => {
    if (workItemChangesDict === null) {
        return null
    }
    return workItemChangesDict.hasOwnProperty(index) ? workItemChangesDict[index] : null
}

const getWorkItemTableRows = (client: Client, currentWorkItems: WorkItemDataType[], isSaving: boolean, onWorkItemChange: (event: React.ChangeEvent, index: number) => void, resourceGroup: ResourceGroupType, workItemChangesDict: WorkItemChangesDictType, setWorkItemTableRows: React.Dispatch<React.SetStateAction<React.ReactNode>>, wbs: WbsDataType[]) => {
    // Check if Current Work Items is set to null
    if (currentWorkItems === null) {
        return
    }
    // Check if there are not Current Work Items
    if (currentWorkItems.length === 0) {
        setWorkItemTableRows([<TableRow className={`${dataRowClass} text-sm`} key={'work-item-no-entries'}>
            <TableBodyItem colSpan={10}>{noEntriesText}</TableBodyItem>
        </TableRow>])
        return
    }
    // Create Table
    const workItemTable = currentWorkItems.map((workItem, index) => {
        // Get this Items WBS Data
        const wbsData: WbsDataType = getWbsDataByList(workItem, wbs)
        // Get the WBS Options for the dropdown menu
        const wbsOptions: Array<WbsOption> = wbsData.wbsEls.map(el => {
            if (el.title.toLowerCase() === "wp") {
                return { key: el.uuid, val: el.element }
            }
        }).filter(el => el !== undefined)
        // Get the WBS Element to display in the table item
        const wbsElement: WbsElementDataType = wbsData.wbsLookUpTable[workItem.wbsElementLookUpId]
        return <FormTableRow
            changes={getWorkItemChangesFromDict(workItemChangesDict, index)}
            client={client}
            key={`work-item-${index}`}
            element={wbsElement}
            index={index}
            isSaving={isSaving}
            item={workItem}
            resourceGroup={resourceGroup}
            onWorkItemChange={onWorkItemChange}
            wbsOptions={wbsOptions}
        />
    })
    setWorkItemTableRows(workItemTable)
}

export default getWorkItemTableRows;