import * as React from "react";

interface SignInMessageProps {
    loginInitiated: boolean,
    loginFailed: boolean
}

const SignInMessage: React.FC<SignInMessageProps> = ({ loginInitiated, loginFailed }) => {
    var message = "Please Sign In."
    if (loginFailed) {
        message = "Unable to Sign in."
    } else if (loginInitiated) {
        message = "Logging In..."
    }
    

    return <div className='h-full w-full flex items-start justify-center'>
        <h1 className="text-lg md:text-xl lg:text-2xl">{message}</h1>
    </div>
}

export default SignInMessage;