import * as React from "react";

interface Props {
    className?: string,
    disabled?: boolean,
    name: string,
    onChange: React.ChangeEventHandler,
    rows?: number,
    value: string
}

const TextArea: React.FC<Props> = ({ className = "", disabled = false, name, onChange, rows = 4, value }) => <textarea
    className={`mb-4 p-1 border-2 w-full resize-none ${className}`}
    name={name}
    onChange={onChange}
    value={value}
    rows={rows}
    disabled={disabled}
/>

export default TextArea