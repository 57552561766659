import * as React from "react";
import IChildrenProps from "interfaces/IChildrenProps";
import { headerTextClassName } from "../../data";

interface Props extends IChildrenProps {
    className?: string
}

const Header: React.FC<Props> = ({ children, className = "" }) => {
    return <p className={`py-4 w-full ${className}`}>{children}</p>
}

export default Header;