import * as React from "react"
import { Table, TableBody, TableHead, TableHeader, TableWrapper } from "components/Table"

interface DataTableHourLogsProps {
    dateHeaderRows: React.ReactNode,
    hourLogTableRows: React.ReactNode
}

const DataTableHourLogs: React.FC<DataTableHourLogsProps> = ({ dateHeaderRows, hourLogTableRows }) => (
    <TableWrapper className="w-full overflow-x-scroll">
        <Table className="min-w-table">
            <TableHeader colSpan={10}>Hour Logs</TableHeader>
            <TableHead>
                {dateHeaderRows}
            </TableHead>
            <TableBody>
                {hourLogTableRows}
            </TableBody>
        </Table>
    </TableWrapper>
)

export default DataTableHourLogs;