import { WorkItemDataType, WorkItemResponseDataType } from "."

const getWorkItemDataFromResponse = (responseData: WorkItemResponseDataType, projectName: string, workItemListId: string) => {
  const fields = responseData.fields
  return {
    id: fields.id,
    siteId: fields.SiteId,
    listId: workItemListId,
    userId: fields.UserId,
    wbsListId: fields.WBSListId,
    wbsElementLookUpId: fields.WBSElementId,
    title: fields.Title,
    projectName: projectName,
    date: fields.Date,
    hoursWorked: fields.HoursWorked
  } as WorkItemDataType
}

export default getWorkItemDataFromResponse;