import * as React from "react";
import IChildrenProps from "interfaces/IChildrenProps";

interface ButtonProps extends IChildrenProps {
    className?: string,
    disabled?: boolean,
    spacingClassName?: string,
    onClick: React.MouseEventHandler,
    variant?: "primary" | "secondary" | "muted" | "cancel" | "confirm",
    type?: "button" | "submit" | "reset"
}

const Button: React.FC<ButtonProps> = ({ children, className = "", disabled = false, variant = "primary", onClick, spacingClassName = "py-2 px-4", type = "button" }) => {
    const variants = {
        "primary": "text-white bg-primary hover:bg-primaryShade disabled:cursor-default disabled:bg-primaryTint",
        "secondary": "text-white bg-separator hover:bg-separatorShade disabled:cursor-default disabled:bg-separatorTint",
        "muted": "text-gray-500 bg-gray-50 hover:bg-gray-100",
        "confirm": "text-white bg-emerald-700 hover:bg-emerald-800 disabled:cursor-default disabled:bg-emerald-500",
        "cancel": "text-white bg-red-700 hover:bg-red-800 disabled:cursor-default disabled:bg-red-500"
    }
    

    return <button
        disabled={disabled}
        type={type}
        className={`flex items-center shadow font-bold ${spacingClassName} ${variants[variant]} ${className}`}
        onClick={onClick}>
        {children}
    </button>
}

interface MiniButtonProps extends ButtonProps {
    align: "left" | "right" | "center" | "none"
}

export const MiniButton: React.FC<MiniButtonProps> = ({ align, children, className = "", disabled = false, variant = "primary", onClick, spacingClassName = "py-2 px-4", type = "button" }) => {
    const getSpacingClassName = () : string => {
        switch (align) {
            case "left":
                return "py-0 px-2 mr-4"
            case "right":
                return "py-0 px-2 ml-4"
            case "center":
                return "py-0 px-2 mx-4"
            case "none":
            default:
                return "py-0 px-2"
        }
    }

    return <Button
        className={className}
        disabled={disabled}
        onClick={onClick}
        spacingClassName={getSpacingClassName()}
        type={type}
        variant={variant}
    >
        {children}
    </Button>
}

export default Button;