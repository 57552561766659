import * as React from "react";
import IChildrenProps from "interfaces/IChildrenProps";

interface Props extends IChildrenProps {
    className?: string
}

const FieldWrapper: React.FC<Props> = ({ children, className = "" }) => <div
    className={`text-left ${className}`}
>
    {children}
</div>

export default FieldWrapper;