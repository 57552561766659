import { TableWrapper, Table, TableHeader, TableHead, TableRow, TableHeadItem, TableBody } from "components/Table";
import IChildrenProps from "interfaces/IChildrenProps";
import * as React from "react";

const DataTableWorkItems: React.FC<IChildrenProps> = ({children}) => (
    <TableWrapper className="w-full overflow-x-scroll">
        <Table className="min-w-table">
            <TableHeader colSpan={10}>Work Items</TableHeader>
            <TableHead>
                <TableRow className="text-sm">
                    <TableHeadItem>{/* Blank */}</TableHeadItem>
                    <TableHeadItem>Date</TableHeadItem>
                    <TableHeadItem>Hours Worked</TableHeadItem>
                    <TableHeadItem>Project</TableHeadItem>
                    <TableHeadItem>WBS Element</TableHeadItem>
                </TableRow>
            </TableHead>
            <TableBody>
                {children}
            </TableBody>
        </Table>
    </TableWrapper>
)

export default DataTableWorkItems;