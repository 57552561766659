import { ResourceGroupType } from "../../data";
import { WorkLogFormDataType } from "features/WorkItem";
import ValidationResult from "interfaces/ValidationResult";

// Validation function for the work log form
const validate = (formData: WorkLogFormDataType, resourceGroup: ResourceGroupType): ValidationResult => {
    // Initialize an array to collect validation results for each work item
    const validationResults: ValidationResult[] = [];
  
    // Check if the selected date is empty
    if (formData.date === "") {
      validationResults.push({
        error: true,
        message: "Please select a date.",
      });
    }
  
    // Validate each work item in the form
    for (let i = 0; i < formData.workItems.length; i++) {
      const item = formData.workItems[i];
  
      if (new Date(formData.date) < new Date(resourceGroup.projects[item.projectWbsListId].startDate)) { // Date strings are in format YYYY-MM-DD
        validationResults.push({
          error: true,
          message: `Date for work item ${i + 1} must be on or after ${resourceGroup.projects[item.projectWbsListId].startDate}.`,
        });
      }

      // Check if the number of hours worked is zero
      if (item.hoursWorked === 0) {
        validationResults.push({
          error: true,
          message: `Please set a number of hours worked > 0 for Work Item ${i + 1}.`,
        });
      }
  
      // Check if the project and WBS element are selected
      if (item.projectWbsListId === "" || item.wbsElementLookUpId === "") {
        validationResults.push({
          error: true,
          message: `Please select both the related project and WBS element for Work Item ${i + 1}.`,
        });
      }
    }
  
    // Determine if there are any validation errors
    const hasErrors = validationResults.some((result) => result.error);
  
    // Return the validation result, including any error messages
    return {
      error: hasErrors,
      message: hasErrors ? validationResults.find((result) => result.error).message : "",
    };
  };

  export default validate;