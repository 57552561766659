import * as React from "react"
import { CaretDown, CaretRight, ValueIcon } from "components/svgs";
import { TreeNode } from "features/WBS";

export interface WbsElement {
    className?: string,
    node: TreeNode
}

const WbsElement: React.FC<WbsElement> = ({ className = "", node }) => {
    const [open, setOpen] = React.useState(false)
    const onClick = node.title === "wp" ? () => { } : (event) => setOpen(!open)
    const icon = node.title === "wp" ? <ValueIcon /> : (open ? <CaretDown /> : <CaretRight />)

    return <div className={className}>
        <ul>
            <li onClick={onClick} className="flex items-center cursor-pointer">{icon}<span className="ml-2">{node.name}</span></li>
            <li className={open ? "" : "hidden"}>{node.children.map((child, index) => <WbsElement
                key={`wbs-node-${node.title}-${index}`}
                className="pl-2 md:pl-4 lg:pl-8"
                node={child}
            />)}</li>
        </ul>
    </div>
}

export default WbsElement;