import { Client } from "@microsoft/microsoft-graph-client"
import { workItemCreate, workItemDelete, workItemGet, workItemUpdate } from "data/Constants"
import { WorkItemDataType, WorkItemResponseDataType, WorkItemSubmissionDataType, WorkItemUpdateType } from "."

export const createWorkItem = (client: Client, siteId: string, listId: string, data: WorkItemSubmissionDataType): Promise<any> => client
    .api(workItemCreate(siteId, listId))
    .post(data)
export const deleteWorkItems = (client: Client, siteId: string, listId: string, itemId: number): Promise<any> => client
    .api(workItemDelete(siteId, listId, itemId))
    .delete()
export const getWorkItems = (client: Client, siteId: string, listId: string, ids: Array<number>): Promise<Array<WorkItemResponseDataType>> => new Promise(async (resolve, reject) => {
    var workItems = []
    for (let id of ids) {
        let res = await client
            .api(workItemGet(siteId, listId, id))
            .get()
        workItems.push(res)
    }
    resolve(workItems)
})
export const updateWorkItems = (client: Client, siteId: string, listId: string, workItemId: string, workItemChanges: WorkItemUpdateType): Promise<any> => client
    .api(workItemUpdate(siteId, listId, workItemId))
    .update(workItemChanges)