import { createSlice, PayloadAction } from "@reduxjs/toolkit"
// User
import { WorkItemDataType } from "."
import merge from "../../utils/merge";

export interface WorkItemState {
    list: Array<WorkItemDataType>
}

export const initialState: WorkItemState = {
    list: null
}

export const mergeWorkItems = (list: Array<WorkItemDataType>, payload: Array<WorkItemDataType>): Array<WorkItemDataType> => {
    let newList = [...list]
    payload.map(workItem => {
        if (list.some(item => item.id === workItem.id && item.listId === workItem.listId && item.wbsListId === workItem.wbsListId)) {
            return
        }
        newList.push(workItem)
    })
    return newList
}

export const workItemSlice = createSlice({
    name: 'workItems',
    initialState,
    reducers: {
        loadWorkItems: (state, action: PayloadAction<Array<WorkItemDataType>>) => {
            // Check if payload or list are null
            if (action.payload === null || state.list === null) {
                state.list = action.payload;
                return
            }
            // Merge items into existing data
            let list = [...state.list]
            state.list = mergeWorkItems(list, action.payload)
        },
        addWorkItems: (state, action: PayloadAction<Array<WorkItemDataType>>) => {
            // Check if payload or list are null
            if (action.payload === null) {
                return
            }
            // Check if list is null
            else if (state.list === null) {
                state.list = action.payload
                return
            }
            // Merge items into existing data
            let list = [...state.list]
            state.list = mergeWorkItems(list, action.payload)
        }
    }
});

export const { loadWorkItems, addWorkItems } = workItemSlice.actions;
export const workItemReducer = workItemSlice.reducer;
export default workItemReducer;