import * as React from "react";

interface Props {
    className?: string,
    option: string,
    label?: string
}

const SelectOption: React.FC<Props> = ({ className = "", label = null, option}) => {
    return <option className={`${className}`} value={option}>
        {label !== null ? label : option.split(/(?=[A-Z])/).join(" ")}
    </option>
}

export default SelectOption;