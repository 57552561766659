import * as React from "react";
import logo from 'images/logo.png';
import IChildrenProps from "interfaces/IChildrenProps"
import { companyName } from "data/index"
import { Login } from "@microsoft/mgt-react";

interface NavBarProps {
    setLoginInitiated: React.Dispatch<React.SetStateAction<boolean>>,
    setLoginComplete: React.Dispatch<React.SetStateAction<boolean>>,
    setLoginFailed: React.Dispatch<React.SetStateAction<boolean>>
}

const NavBar: React.FC<NavBarProps> = ({ setLoginInitiated, setLoginComplete, setLoginFailed }) => {
    const [show, setShow] = React.useState(false);

    return <nav className="bg-white text-primary border-b-4 border-separator">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <NavBarLogo href={""} />
            <div className="flex">
                
                <NavBarCollapseButton setShow={setShow} show={show} />
            </div>
            <NavBarAnchorWrapper show={show}>
                <li>
                    <Login loginView="compact"
                        loginInitiated={(event) => setLoginInitiated(true)}
                        loginCompleted={(event) => setLoginComplete(true)}
                        loginFailed={(event) => setLoginFailed(true)}
                    />
                </li>
            </NavBarAnchorWrapper>
        </div>
    </nav>
}

export default NavBar;

interface NavBarLogoProps {
    href: string
}

export const NavBarLogo: React.FC<NavBarLogoProps> = ({ href }) => <a href={href} className="flex items-center">
    <img src={logo} className="h-14 mr-3" alt={`${companyName} Logo`} />
</a>

interface NavBarCollapseButtonProps {
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const NavBarCollapseButton: React.FC<NavBarCollapseButtonProps> = ({ show, setShow }) => <button type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" onClick={() => setShow(!show)}>
    <span className="sr-only">Open main menu</span>
    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
    </svg>
</button>

interface NavBarAnchorWrapperProps extends IChildrenProps {
    show: boolean
}

export const NavBarAnchorWrapper: React.FC<NavBarAnchorWrapperProps> = ({ children, show }) => <div className={`${show ? '' : 'hidden'} w-full md:block md:w-auto`}>
    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-none bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white">
        {children}
    </ul>
</div>

interface NavBarAnchorItem extends IChildrenProps {
    href: string,
    variant: "primary" | "standard"
}

export const NavBarAnchorItem: React.FC<NavBarAnchorItem> = ({ href, variant = "standard", children }) => {
    const variants = {
        primary: 'bg-transparent text-primary md:bg-primary md:hover:bg-primary md:text-white md:hover:text-white',
        standard: 'bg-transparent md:hover:bg-primary text-primary md:hover:text-white'
    }

    return <li>
        <a href={href} className={`block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:border-0 md:px-4 md:py-1 md:rounded-none ${variants[variant]}`}>{children}</a>
    </li>
}