import * as React from "react"
import { TableBodyItem, TableRow, activeItemClass, changedRowClass, dataItemClass, dataRowClass, highlightedItemClass } from "components/Table"
import { TableEntriesData, noEntriesText } from "containers/DataTable"

const getHourLogTableRows = (hourLogData: TableEntriesData, setHourLogTableRows: React.Dispatch<React.SetStateAction<React.ReactNode>>) => {
    // Check if Hour Log Data is set to null
    if (hourLogData === null) {
        return
    }
    // Check if there are no entries
    if (hourLogData.length === 0) {
        setHourLogTableRows([<TableRow className={`${dataRowClass} text-sm`} key={'hour-log-no-entries'}>
            <TableBodyItem colSpan={10}>{noEntriesText}</TableBodyItem>
        </TableRow>])
        return
    }
    // Initialize total counter
    const totalHoursByDay = new Array(7).fill(0)
    var totalHours = 0
    var hasChanges = false
    var dayHasChange = new Array(7).fill(false)
    // Create Table
    const hourLogTable = hourLogData.map((entry, index) => {
        const rowClass = entry.rowChanged ? changedRowClass : dataRowClass
        if (entry.rowChanged) {
            hasChanges = true
        }
        // Get all individual WBS Element Rows
        console.debug("entry.colChanged", entry.colChanged)
        return <TableRow className={`${rowClass} text-sm`} key={`hour-log-table-row-${index}`}>
            <TableBodyItem className="truncated min-w-tenrem">{entry.element}</TableBodyItem>
            {entry.hoursByDay.map((hours, day) => {
                totalHoursByDay[day] += hours
                totalHours += hours
                const itemClass = entry.colChanged[day] ? highlightedItemClass : dataItemClass
                console.debug(entry.colChanged, day, entry.colChanged[day], itemClass)
                if (entry.colChanged[day]) {
                    hasChanges = true
                    dayHasChange[day] = true
                }
                return <TableBodyItem className={itemClass} key={`hour-${day}-${index}`}>
                    {hours}
                </TableBodyItem>
            })}
            <TableBodyItem>{entry.totalHours}</TableBodyItem>
        </TableRow>
    })
    // Get Totals Row
    const totalClass = hasChanges ? activeItemClass : dataItemClass
    hourLogTable.push(<TableRow className={`${dataRowClass} text-sm`} key={'hour-log-table-summary-row'}>
        <TableBodyItem>Total</TableBodyItem>
        {totalHoursByDay.map((hours, day) => {
            const hourItemClass = dayHasChange[day] ? highlightedItemClass : dataItemClass
            return <TableBodyItem className={hourItemClass} key={`hours-total-${day}`}>
                {hours}
            </TableBodyItem>
        })}
        <TableBodyItem className={totalClass}>{totalHours}</TableBodyItem>
    </TableRow>)
    setHourLogTableRows(hourLogTable)
    return
}

export default getHourLogTableRows;