import * as React from "react";

interface Props {
    children: React.ReactNode,
    className?: string
}

const Wrapper: React.FC<Props> = ({ children, className = "" }) => {
    return <div className={`text-center mx-auto ${className}`}>
        {children}
    </div>
}

export default Wrapper;