import { Client } from "@microsoft/microsoft-graph-client"
import ToggleItem from "components/ToggleItem"
import { activeRowClass, dataRowClass, TableBodyItem, TableRow } from "components/Table"
import { WbsOption, WorkItemChangesType } from "containers/DataTable"
import { ResourceGroupType } from "../data"
import { WbsElementDataType } from "features/WBS"
import { WorkItemDataType, deleteWorkItems } from "features/WorkItem"
import React from "react"
import getDateFromString from "utils/getDateFromString"
import formatDate from "utils/formatDate"

// Constants
const formTableRowConfirmationMessage = "Are you sure that you want to delete this work item? This cannot be undone."

interface FormTableRowProps {
    changes: WorkItemChangesType,
    client: Client,
    element: WbsElementDataType,
    index: number, // Index number from the map loop that the component is generated in, used for change tracking and component key creation
    isSaving: boolean,
    item: WorkItemDataType,
    onWorkItemChange: (event: React.ChangeEvent, index: number) => void
    resourceGroup: ResourceGroupType,
    wbsOptions: Array<WbsOption>
}

const FormTableRow: React.FC<FormTableRowProps> = ({ changes, client, element, index, isSaving, item, onWorkItemChange, resourceGroup, wbsOptions }) => {
    const onClickDelete = (event: React.MouseEvent) => {
        if (confirm(formTableRowConfirmationMessage)) {
            deleteWorkItems(client, resourceGroup.workItemSiteId, resourceGroup.workItemListId, parseInt(item.id))
                .then((results) => window.location.reload())
                .catch((error) => { throw new Error(error) })
        }
    }
    // Calls Higher Order Component to handle React.ChangeEvent
    const onClickRowChanges = (event: React.ChangeEvent) => onWorkItemChange(event, index)

    // Change Flags
    const rowHasChanged         = changes === null ? false : Object.values(changes).some(val => val)
    const dateHasChanged        = rowHasChanged    ? changes.date               : false
    const hoursWorkedHasChanged = rowHasChanged    ? changes.hoursWorked        : false
    const elementHasChanged = rowHasChanged ? changes.wbsElementLookUpId : false
    // Display Values
    const displayDate = formatDate(getDateFromString(item.date))
    // Render
    return <TableRow className={`${rowHasChanged ? activeRowClass : dataRowClass} text-sm`}>
        <TableBodyItem onClick={onClickDelete} className="text-center font-bold text-white bg-red-500 hover:bg-red-700 hover:cursor-pointer">X</TableBodyItem>
        <ToggleItem
            as={<input
                className="text-black"
                autoFocus
                disabled={isSaving}
                name="date"
                type="date"
                value={displayDate}
                onChange={onClickRowChanges} />}
            hasChanged={dateHasChanged} />
        <ToggleItem
            as={<input
                className="text-black"
                autoFocus
                disabled={isSaving}
                name="hoursWorked"
                type="number"
                value={item.hoursWorked}
                onChange={onClickRowChanges} />}
            hasChanged={hoursWorkedHasChanged} />
        <TableBodyItem>{item.projectName}</TableBodyItem>
        <ToggleItem
            as={<select
                className="text-black"
                autoFocus
                name="wbsElementLookUpId"
                onChange={onClickRowChanges}
                value={item.wbsElementLookUpId} >
                {wbsOptions.map(opt => <option
                    disabled={isSaving}
                    key={`option-${index}-${opt.key}`}
                    value={opt.key}>
                    {opt.val}
                </option>)}
            </select>}
            displayValue={element.element}
            hasChanged={elementHasChanged} />
    </TableRow>
}

export default FormTableRow;