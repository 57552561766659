import * as React from "react";
import { defaultErrorMessage } from "../../data";

interface Props {
    success: boolean,
    error: boolean,
    msg: string
}

const Alert: React.FC<Props> = ({ success, error, msg }) => {
    if (success)
        return <div className="w-full p-4 mt-6 text-white bg-emerald-700 whitespace-pre-wrap">{msg}</div>
    else if (error)
        return <div className="w-full p-4 mt-6 text-white bg-red-700 whitespace-pre-wrap">{msg != "" ? msg : defaultErrorMessage}</div>
    return null
}

export default Alert;