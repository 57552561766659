import * as React from "react";
import IChildrenProps from "interfaces/IChildrenProps"
import { baseTextClassName } from "../data";

interface TabSectionProps extends IChildrenProps { }

export const TabSection: React.FC<TabSectionProps> = ({ children }) => {
    return <ul className={`md:flex md:flex-wrap font-medium text-center text-black border-b border-gray-200 ${baseTextClassName}`}>
        {children}
    </ul>
}

interface TabProps extends IChildrenProps {
    href: string,
    index: number,
    setTab: React.Dispatch<React.SetStateAction<number>>,
    tab: number
}

export const Tab: React.FC<TabProps> = ({ children, href, index, setTab, tab }) => {
    // href={href}
    return <li className="border-b border-white md:border-none md:mr-2" onClick={() => setTab(index)}>
        <a className={`w-full inline-block cursor-pointer p-4 md:rounded-t-lg text-white ${index == tab ? "bg-primary" : "bg-separator"}`}>{children}</a>
    </li>
}

interface TabContentsProp extends IChildrenProps {
    index: number,
    tab: number
}

export const TabContents: React.FC<TabContentsProp> = ({ children, index, tab }) => {
    return index == tab ? <div>
        {children}
    </div> : null
}