import { createWorkItem, deleteWorkItems, getWorkLogFormState } from "features/WorkItem";
import { ResourceGroupType } from "../../data";
import { WorkItemSubmissionDataType, WorkLogFormDataType } from "features/WorkItem/workItemTypes";
import { Client } from "@microsoft/microsoft-graph-client";
import ValidationResult from "interfaces/ValidationResult";
import { UserDataType } from "features/User";

// Function for submitting the work log form
const submit = async (client: Client, formData: WorkLogFormDataType, resourceGroup: ResourceGroupType, user: UserDataType): Promise<ValidationResult> => {
  try {
    // Arrays to store successfully created work item IDs and error messages
    const workItemIds: number[] = [];
    const workItemErrors: string[] = [];
  
    // Iterate through each work item in the form
    for (const item of formData.workItems) {
      // Get project information
      const project = resourceGroup.projects[item.projectWbsListId];

      console.debug(item)

      // Prepare data for creating a work item
      const itemData: WorkItemSubmissionDataType = {
        fields: {
          Title: `${project.projectName} [API]`,
          UserId: user.id,
          SiteId: project.siteId,
          WBSListId: item.projectWbsListId,
          WBSElementId: item.wbsElementLookUpId, // item.wbsElementLookUpId
          Date: formData.date,
          HoursWorked: item.hoursWorked,
        },
      };

      console.debug(itemData)

      try {
        // Create the work item and store the ID
        const workItemResult = await createWorkItem(
          client,
          resourceGroup.workItemSiteId,
          resourceGroup.workItemListId,
          itemData
        );
        workItemIds.push(parseInt(workItemResult.id));
      } catch (error) {
        // Collect any error messages during work item creation
        workItemErrors.push(error.message);
      }
    }

    if (workItemErrors.length) {
      // If there are errors, delete the created work items and display an error message
      for (const itemId of workItemIds) {
        try {
          await deleteWorkItems(
            client,
            resourceGroup.workItemSiteId,
            resourceGroup.workItemListId,
            itemId
          );
        } catch (error) {
          // Collect any additional error messages during deletion
          workItemErrors.push(error.message);
        }
      }
  
      const errorMsg = "The following errors have occurred:\n" + workItemErrors.join("\n");
      return {
          error: true,
          message: errorMsg
      }
    } else if (workItemIds.length) {
      return {
          error: false,
          message:"Successfully Created Hour Log!"
      }
    } else {
      return {
          error: false,
          message:"SYS"
      }
    }
  } catch (error) {
    console.error(error);
    return {
      error: false,
      message:"SYS"
    }
  }
};

export default submit;