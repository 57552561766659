import * as React from "react"
import { Client } from "@microsoft/microsoft-graph-client";

import { getUnsavedChangesMessage } from "data/Constants";
import convertToGraphFieldName from "../utils/convertToGraphFieldName";
import { WorkItemDataType, deleteWorkItems, updateWorkItems as RemoteUpdateWorkItems } from "features/WorkItem";

import { FeatureProps } from "containers/ConnectedApp";
import DataTableControls from "components/DataTableControls";
import DataTableWorkItems from "components/DataTableWorkItems";
import DataTableHourLogs from "components/DataTableHourLogs";
import calendarDiffrentialToDate from "utils/calendarDifferentialToDate";
import transformWorkItemData from "containers/effects/transformWorkItemData";
import convertMillisecondsToDays from "utils/convertMillisecondsToDays";
import getHourLogTableRows from "containers/effects/getHourLogTableRows";
import getWorkItemTableRows from "containers/effects/getWorkItemTableRows";
import getDateHeaderRows from "containers/effects/getDateHeaderRows";

// Constants
const dateAdjustment = 14
export const noEntriesText = 'No entries for this week!'
// Types
type TableEntryFormat = {
  element: string,
  hoursByDay: Array<number>,
  totalHours: number,
  rowChanged: boolean,
  colChanged: Array<boolean>
}
export type TableEntriesData = Array<TableEntryFormat>;

export type WbsOption = {
  key: string,
  val: string
}

export type WorkItemChangesType = {
  date: boolean,
  hoursWorked: boolean,
  wbsElementLookUpId: boolean
}

export type WorkItemChangesDictType = {
  [index: number]: WorkItemChangesType
}

// Components
interface DataTableProps extends FeatureProps {
  client: Client,
  reloadData: () => void,
  setRequireConfirmation: React.Dispatch<React.SetStateAction<boolean>>,
}

const DataTable: React.FC<DataTableProps> = ({ client, reloadData, resourceGroup, user, setRequireConfirmation, workItems, wbs }) => {
  /*
    * Set Up
    */
  // Constants
  // Calculate differentials for displaying data
  const today = new Date;
  const earliest = new Date(2022, 0, 1)

  const firstOfCurrentWeekDiff = today.getDate() - today.getDay()
  console.log("First of Current Week Diff: ", firstOfCurrentWeekDiff)
  const firstOfCurrentWeek = new Date(new Date().setDate(firstOfCurrentWeekDiff))

  const firstOfEarliestWeekDiff = earliest.getDate() - earliest.getDay()
  const firstOfEarliestWeek = new Date(new Date(earliest).setDate(firstOfEarliestWeekDiff))

  const secondsToEarliest = firstOfEarliestWeek.getTime() - firstOfCurrentWeek.getTime()
  const earliestDiff = Math.ceil(convertMillisecondsToDays(secondsToEarliest))
  console.log("ED: ", earliestDiff, convertMillisecondsToDays(firstOfEarliestWeek.getTime()), convertMillisecondsToDays(firstOfCurrentWeek.getTime()))
  // Local State - new Date(new Date().setDate(diff))
  const [calendarDifferential, setCalendarDifferential]: [number, React.Dispatch<React.SetStateAction<number>>] = React.useState(firstOfCurrentWeekDiff);
  const [currentWorkItems, setCurrentWorkItems]: [Array<WorkItemDataType>, React.Dispatch<React.SetStateAction<Array<WorkItemDataType>>>] = React.useState(null)
  const [changesExist, setChangesExist]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = React.useState(false);    
  const [workItemChanges, setWorkItemChanges]: [WorkItemChangesDictType, React.Dispatch<React.SetStateAction<WorkItemChangesDictType>>] = React.useState(null)
  const [isSaving, setIsSaving]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = React.useState(false)
  // customSetChangesExist - Handles behaviour for changes to Work Items in this component and lower order components.
  // name:  string | null - Name of the field or null to reset flags.
  // value: string | null - New value of the field or null to reset flags.
  // index: number | null - Index of the Work Item in currentWorkItems or null to reset all flags and values.
  const customSetChangesExist = (name: string, value: string, index: number | null) => {
      if (index === null || name === null || value === null) {
          throw new Error("Index, name and value must all be set.")
      } else {
          // Otherwise, track existence of changes in changesExist and changedWorkItems
          let newChangedWorkItems = workItemChanges === null ? {} : workItemChanges
          if (newChangedWorkItems.hasOwnProperty(index)) {
              // If changes already exist for this item
              newChangedWorkItems[index][name] = true
          } else {
              // No currently tracked changes exist for this item
              newChangedWorkItems[index] = {
                  date: name === "date" ? true : false,
                  hoursWorked: name === "hoursWorked" ? true : false,
                  wbsElementLookUpId: name === "wbsElementLookUpId" ? true : false,
              }
          }
          setWorkItemChanges(newChangedWorkItems)
          setChangesExist(true)
          setRequireConfirmation(true)
      }
  }
  // Clears all changes and resets all flags
  const clearChanges = (): void => {
      setRequireConfirmation(false)
      setChangesExist(false)
      setWorkItemChanges(null)
      getCurrentWorkItems()
  }
  // Get First and Last Date of Current Range for filtering out logs that are outside of the given date range
  const firstDate = calendarDiffrentialToDate(calendarDifferential)
  const lastDate = calendarDiffrentialToDate(calendarDifferential + 6)
  const getRange = () => ({
      firstDate,
      lastDate
  })
  // Check Work Item Date
  const checkWorkItemOutOfRange = (workItem, firstDate, lastDate) => {
      // Date Calculation
      let workDate = new Date(workItem.date);
      // If the work item date is less than the first day of the currently viewed week or more than the last day, return from function
      if (workDate < firstDate || workDate > lastDate) return true;
      return false;
  }
    
    
  // Get Current Work Items by filtering Work Items store
  const getCurrentWorkItems = () => {
      if (workItems !== null) {
          // Get dates for filtering out logs that are outside of the given date range
          let { firstDate, lastDate } = getRange()
          let items = workItems.filter(item => !checkWorkItemOutOfRange(item, firstDate, lastDate))
          setCurrentWorkItems(items)
      }
  }
  /*
   * Use Effects Hooks
   */
  // Set Current Work Items
  React.useEffect(() => {
      getCurrentWorkItems()
  }, [workItems, calendarDifferential])
  // Transform Work Item Data into a weekly or daily table format
  const [hourLogData, setHourLogData]: [TableEntriesData, React.Dispatch<React.SetStateAction<TableEntriesData>>] = React.useState(null)
  React.useEffect(() => {
      transformWorkItemData(currentWorkItems, workItemChanges, calendarDifferential, wbs, setHourLogData);
  }, [currentWorkItems])
  // Get Hour Log Table Rows
  const [hourLogTableRows, setHourLogTableRows]: [React.ReactNode, React.Dispatch<React.SetStateAction<React.ReactNode>>] = React.useState(null)
  React.useEffect(() => {
      getHourLogTableRows(hourLogData, setHourLogTableRows);
  }, [hourLogData])
  // Get Work Item Table Rows
  const [workItemTableRows, setWorkItemTableRows]: [React.ReactNode, React.Dispatch<React.SetStateAction<React.ReactNode>>] = React.useState(null)
  React.useEffect(() => {
      getWorkItemTableRows(client, currentWorkItems, isSaving, onWorkItemChange, resourceGroup, workItemChanges, setWorkItemTableRows, wbs);
  }, [currentWorkItems])
  // Get Date Headers Row
  const [dateHeaderRows, setDateHeaderRows]: [React.ReactNode, React.Dispatch<React.SetStateAction<React.ReactNode>>] = React.useState(null)
  React.useEffect(() => {
      getDateHeaderRows(calendarDifferential, setDateHeaderRows);
  }, [calendarDifferential])
  /*
    * Update Handlers
    */
  // Update Current Work Items
  const updateCurrentWorkItems = (name: string, value: string, index: number) => {
      let newCurrentWorkItems = [...currentWorkItems]
      // Check if type should be number
      // Check if the type is a date and append a string to d
      // TODO: Fix the data type so this is not necessary
      let updatedItem = null
      if (name === "hoursWorked") {
          updatedItem = {
              ...currentWorkItems[index],
              [name]: parseFloat(value)
          }
      } else {
          updatedItem = {
              ...currentWorkItems[index],
              [name]: value
          }
      }
      // Update
      newCurrentWorkItems[index] = updatedItem
      setCurrentWorkItems([...newCurrentWorkItems])
  }
  // Event Handler
  const onWorkItemChange = (event: React.ChangeEvent, index: number): void => {
      let { name, value } = event.target as HTMLInputElement
      updateWorkItemLocalData(name, value, index)
      customSetChangesExist(name, value, index)
  }
  // Get Work Item Table Rows
  const getWorkItemChanges = (index: number): WorkItemChangesType => {
      if (workItemChanges === null) {
          return null
      }
      return workItemChanges.hasOwnProperty(index) ? workItemChanges[index] : null
  }
  // Sets State
  const updateWorkItemLocalData = (name: string, value: string, index: number) => {
      // Check Work Items are loaded.
      if (currentWorkItems === null) {
          throw new Error("Work Item Update attempted before Work Item data was loaded.")
      }
      // Check Index exists.
      if (!currentWorkItems.hasOwnProperty(index)) {
          throw new Error(`Work Item Update attempted on a Work Item at index ${index} which does not exist.`)
      }
      // Check Name exists
      else if (!currentWorkItems[index].hasOwnProperty(name)) {
          throw new Error(`Work Item Update attempted on field '${name}' which does not exist.`)
      }
      // Update Value Locally
      else {
          updateCurrentWorkItems(name, value, index)
      }
  }
  // Checks if confirmation is necessary after changes have been made to proceed with a requested action.
  const afterChangeConfirmation = (callback: Function): boolean => {
      // Check for changes
      let res = true
      if (changesExist) {
          res = confirm(getUnsavedChangesMessage("change the date range"))
      }
      // Check res flag
      if (res) {
          // Perform callback+clear, return true
          callback()
          clearChanges()
          return true
      }
      // Return false
      return false
  }

  /*
    * On Click Handlers
    */
  const onClickEarliest = (event: React.MouseEvent) => afterChangeConfirmation(() => setCalendarDifferential(earliestDiff + firstOfCurrentWeekDiff)) // Why is this subtraction necessary?
  const onClickBack = (event: React.MouseEvent) => afterChangeConfirmation(() => setCalendarDifferential(calendarDifferential - 7));
  const onClickSave = (event: React.MouseEvent) => {
      setIsSaving(true)
      Object.keys(workItemChanges).map(workItemIndex => {
          const workItem = currentWorkItems[parseInt(workItemIndex)]
          const changes = workItemChanges[parseInt(workItemIndex)]
          let postData = {}
          Object.entries(changes).map(([fieldName, changeFlag]) => {
              if (changeFlag) {
                  postData[convertToGraphFieldName(fieldName)] = workItem[fieldName]
              }
          })
          RemoteUpdateWorkItems(client, resourceGroup.workItemSiteId, resourceGroup.workItemListId, workItem.id, postData).then((result) => {
              reloadData()
          }).catch((error) => {
              console.error(error)
          })
      })
  }
  const onClickCancel = (event: React.MouseEvent) => {
      clearChanges()
  }
  const onClickSetDate = (event: React.MouseEvent, date: string) => {
    let diff = daysBetweenFirstSundays(date)
    setCalendarDifferential(firstOfCurrentWeekDiff - diff)
  }
  const daysBetweenFirstSundays = (dateString) => {
    console.log(dateString)
    // Parse the given date string
    const givenDate = new Date(dateString);
    console.log(givenDate)

    // Find the first Sunday before the given date
    const firstSundayBeforeGivenDate = new Date(givenDate);
    firstSundayBeforeGivenDate.setDate(givenDate.getDate() - givenDate.getDay());
    console.log(firstSundayBeforeGivenDate)

    // Get today's date
    const today = new Date();

    // Find the first Sunday before today
    const firstSundayBeforeToday = new Date(today);
    firstSundayBeforeToday.setDate(today.getDate() - today.getDay());
    console.log(firstSundayBeforeToday)

    // Calculate the difference in days
    const msPerDay = 24 * 60 * 60 * 1000;
    const differenceInMs = firstSundayBeforeToday.getTime() - firstSundayBeforeGivenDate.getTime();
    return Math.round(differenceInMs / msPerDay);
  }

  const onClickForward = (event: React.MouseEvent) => afterChangeConfirmation(() => setCalendarDifferential(calendarDifferential + 7));
  const onClickLatest = (event: React.MouseEvent) => afterChangeConfirmation(() => setCalendarDifferential(firstOfCurrentWeekDiff))
  /* 
   * Render
   */
  // Return null if work items is currently 
  if (currentWorkItems === null) {
    return null
  }
  // Otherwise, render Data Table
  return <div>
    <DataTableHourLogs 
      dateHeaderRows={dateHeaderRows}
      hourLogTableRows={hourLogTableRows}
    />

    <DataTableWorkItems>
      {workItemTableRows}
    </DataTableWorkItems>

    <DataTableControls
      calendarDifferential={calendarDifferential}
      changesExist={changesExist}
      earliestDiff={earliestDiff}
      firstOfCurrentWeekDiff={firstOfCurrentWeekDiff}
      isSaving={isSaving}
      onClickBack={onClickBack}
      onClickCancel={onClickCancel}
      onClickEarliest={onClickEarliest}
      onClickForward={onClickForward}
      onClickLatest={onClickLatest}
      onClickSave={onClickSave}
      onClickSetDate={onClickSetDate}
    />
  </div>
}

export default DataTable;