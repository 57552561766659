import * as React from "react";
import { TableBodyItem } from "components/Table";

interface ToggleItemProps {
    as: React.ReactElement,
    hasChanged: boolean,
    displayValue?: string
}
// TODO: Rework, get rid of code duplication
const ToggleItem: React.FC<ToggleItemProps> = ({ as, hasChanged, displayValue = null }) => {
    const [edit, setEdit]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = React.useState(false)
    const disabled: boolean = as.props.disabled !== undefined ? as.props.disabled : false
    const onChange: React.ChangeEventHandler = as.props.onChange !== undefined ? as.props.onChange : () => { throw new Error ("No onChange Handler Found.") }
    const value: string | number = as.props.value

    if (edit && !disabled) {
        // editOnBlur - Sets edit flag to false on React.FocusEvent.
        const editOnBlur = (event: React.FocusEvent) => {
            let { name, value } = event.target as HTMLInputElement
            setEdit(false)
        }
        // editOnChange - Calls Higher Order Component to handle React.ChangeEvent, then sets edit flag to false on React.ChangeEvent.
        const editOnChange = (event: React.ChangeEvent) => {
            let { name, value } = event.target as HTMLInputElement
            onChange(event)
        }
        return <TableBodyItem>
            {React.cloneElement(as, {
                ...as.props,
                onBlur: editOnBlur,
                onChange: editOnChange
            })}
        </TableBodyItem>
    }
    const viewOnClick = (event: React.MouseEvent) => !disabled ? setEdit(true) : () => { }
    const className = hasChanged ? "font-bold italic" : ""
    return <TableBodyItem className={className} onClick={viewOnClick}>{displayValue === null ? value : displayValue}</TableBodyItem>
}

export default ToggleItem;