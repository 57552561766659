import Button from "components/Button"
import { TableWrapper } from "components/Table";
import * as React from "react";
import { FormInput } from "./Form";

interface DataTableControlsProps {
  calendarDifferential: number,
  earliestDiff: number,
  firstOfCurrentWeekDiff: number,
  isSaving: boolean,
  changesExist: boolean,
  onClickBack: React.MouseEventHandler,
  onClickCancel: React.MouseEventHandler,
  onClickEarliest: React.MouseEventHandler,
  onClickForward: React.MouseEventHandler,
  onClickLatest: React.MouseEventHandler,
  onClickSave: React.MouseEventHandler,
  onClickSetDate: (event: React.MouseEvent, date: string) => void
}

const dateAdjustment = 0

const DataTableControls: React.FC<DataTableControlsProps> = ({
  calendarDifferential,
  changesExist,
  earliestDiff,
  firstOfCurrentWeekDiff,
  isSaving,
  onClickBack, 
  onClickCancel, 
  onClickEarliest, 
  onClickForward, 
  onClickLatest,
  onClickSave,
  onClickSetDate
}) => {
  const [date, setDate] = React.useState("")
  const onDateChange = (event: React.ChangeEvent) => {
    const { name, value } = event.target as HTMLInputElement
    setDate(value)
  }

  return <TableWrapper>
    <div className="inline-flex w-full text-center mb-4">
      <Button
        className="w-full"
        onClick={onClickEarliest}
        disabled={((calendarDifferential) <= earliestDiff) || isSaving}>
        Earliest
      </Button>
      <Button
        className="w-full"
        onClick={onClickBack}
        disabled={((calendarDifferential) <= earliestDiff)  || isSaving}>
        Back
      </Button>
      <Button
        className="w-full"
        onClick={onClickForward}
        disabled={((calendarDifferential + 7) > firstOfCurrentWeekDiff) || isSaving}>
        Forward
      </Button>
      <Button
        className="w-full"
        onClick={onClickLatest}
        disabled={((calendarDifferential + 7) > firstOfCurrentWeekDiff) || isSaving}>
        Latest
      </Button>
    </div>
    <div className="inline-flex w-full text-center">
      <input className="flex items-center shadow py-2 px-4 border-2 w-full" name="date" type="date" onChange={onDateChange} value={date} />
      <Button
        className="w-full"
        onClick={(event) => onClickSetDate(event, date)}
        disabled={changesExist || isSaving}>
        Set Date
      </Button>
      <Button
        className="w-full"
        onClick={onClickSave}
        variant={"confirm"}
        disabled={!changesExist || isSaving}>
        Save
      </Button>
      <Button
        className="w-full"
        onClick={onClickCancel}
        variant={"cancel"}
        disabled={!changesExist || isSaving}>
        Cancel
      </Button>
    </div>
  </TableWrapper>
}

export default DataTableControls