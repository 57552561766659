import * as React from "react";
import IChildrenProps from "../../interfaces/IChildrenProps"

interface TableWrapperProps extends IChildrenProps {
    className?: string
}

export const TableWrapper: React.FC<TableWrapperProps> = ({ children, className = "" }) => <div className={`relative overflow-x-auto mb-4 ${className}`}>
    {children}
</div>

interface TableProps extends IChildrenProps {
    className?: string
}

export const Table: React.FC<TableProps> = ({ children, className = "" }) => <table className={`w-full table table-auto text-left break-all ${className}`}>
    {children}
</table>

interface TableHeadProps extends IChildrenProps { }

export const TableHead: React.FC<TableHeadProps> = ({ children }) => <thead className="bg-primary border-b border-white text-white">
    {children}
</thead>

interface TableHeaderProps extends IChildrenProps {
    colSpan?: number,
    className?: string,
    onClick?: React.MouseEventHandler
}

export const TableHeader: React.FC<TableHeaderProps> = ({ children, colSpan = 1, className = "" }) => <TableHead>
    <TableRow>
        <TableHeadItem colSpan={colSpan} className={className}>{children}</TableHeadItem>
    </TableRow>
</TableHead>

export const TableHeadItem: React.FC<TableHeaderProps> = ({ children, colSpan = 1, className = "", onClick }) => <th className={`p-2 ${className}`} colSpan={colSpan} onClick={onClick}>
    {children}
</th>

interface TableBodyProps extends IChildrenProps { }

export const TableBody: React.FC<TableBodyProps> = ({ children }) => <tbody>
    {children}
</tbody>

interface TableBodyItemProps extends IChildrenProps {
    className?: string,
    colSpan?: number,
    onClick?: React.MouseEventHandler
}

export const TableBodyItem: React.FC<TableBodyItemProps> = ({ children, className = "", colSpan = 1, onClick }) => <td className={`p-2 ${className} ${onClick !== undefined ? "cursor-pointer" : ""}`} colSpan={colSpan} onClick={onClick}>
    {children}
</td>



interface TableRowProps extends IChildrenProps {
    className?: string
}

export const TableRow: React.FC<TableRowProps> = ({ children, className }) => <tr className={className}>
    {children}
</tr>

// Base Classes
const dataBase = "bg-separator border-b border-white"
const activeBase = "bg-secondary border-b border-white"
// Table Item Classes
export const dataItemClass = "text-white"
export const activeItemClass = "bg-secondary text-white"
export const highlightedItemClass = `${dataItemClass} font-bold italic`
// Table Row Classes
export const dataRowClass = `${dataBase} text-white`
export const activeRowClass = `${activeBase} text-white`
export const highlightedRowClass = `${dataBase} text-white italic`
export const changedRowClass = `${highlightedRowClass} font-bold`