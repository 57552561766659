import { FeatureProps } from "../containers/ConnectedApp";

const isDataLoaded = (data: FeatureProps) => {
    if (data.user === null) {
        return false
    } else if (data.resourceGroup === null) {
        return false
    } else if (data.workItems === null) {
        return false
    } else if (data.wbs === null) {
        return false
    }
    return true;
}

export default isDataLoaded;