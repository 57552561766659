import React from "react";
import { FormFieldWrapper, FormInput, FormLabel, FormSelect, FormSelectOption, FormTextArea } from ".";

interface CommonProps {
    condition?: (formData: { [key: string]: any }) => boolean,
    disabled?: boolean,
    is: "input" | "textarea" | "select" | React.ComponentType
    label?: string,
    name: string,
    onChange?: React.ChangeEventHandler,
    shouldRenderConditional?: boolean,
    value?: string
}

type InputConditionalProps = | {
    is: "input",
    type?: "text" | "date" | "tel" | "email" | "number" | "checkbox"
} | {
    is: "textarea" | "select" | React.ComponentType,
    type?: never
}

type OptionObject = {
    value: string,
    label: string
}

type SelectConditionalProps = | {
    is: "select",
    options?: Array<string> | Array<OptionObject>,
    otherable?: boolean,
    otherValue?: string
} | {
    is: "input" | "textarea" | React.ComponentType,
    options?: never,
    otherable?: never,
    otherValue?: never
}

type TextAreaConditionalProps = | {
    is: "textarea",
    rows?: number
} | {
    is: "input" | "select" | React.ComponentType,
    rows?: never
}

export type FieldProps = CommonProps & InputConditionalProps & SelectConditionalProps & TextAreaConditionalProps

const Field: React.FC<FieldProps> = ({
    disabled = false,
    is,
    label,
    name,
    onChange = () => { },
    options = ["yes", "no"],
    otherable = false,
    otherValue,
    rows,
    type,
    value
}) => {
    let component = null
    if (is == "input") {
        component = <FormInput name={name} onChange={onChange} value={value} type={type} disabled={disabled} />
    } else if (is == "textarea") {
        component = <FormTextArea name={name} onChange={onChange} rows={rows} value={value} disabled={disabled} />
    } else if (is == "select") {
        component = <FormSelect name={name} onChange={onChange} value={value} disabled={disabled}>
            {options.map((option, index) => {
                // Check if OptionObject or String
                if (typeof option == "object") {
                    return <FormSelectOption option={option.value} label={option.label} key={`${name}-${index}`} />
                } else {
                    return <FormSelectOption option={option} key={`${name}-${index}`} />
                }

            })}
        </FormSelect>
    }

    let render = <FormFieldWrapper>
        <FormLabel label={label} name={name} />
        {component}
    </FormFieldWrapper>

    if (otherable) {
        return <>
            {render}
            {value == "other" && <FormFieldWrapper>
                <FormLabel label={`Other ${label}`} name={`${name}Other`} />
                <FormInput name={`${name}Other`} onChange={onChange} value={otherValue} />
            </FormFieldWrapper>}
        </>
    }
    return render
}

export default Field;