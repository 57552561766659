// Converts from YYYY-MM-DD to Date object
const getDateFromString = (string: string): Date => {
    if (string.includes("T07:00:00Z")) {
        return new Date(string)
    } else {
        var date = new Date(string)
        date.setDate(date.getDate() + 1)
        return date
    }
}

export default getDateFromString;