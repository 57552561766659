import * as React from "react";
// Data/Utilities (organized alphabetically)
import {
  baseTextClassName,
  createHourLogHash,
  dashboardHashCodes,
  dashboardHeader,
  getUnsavedChangesMessage,
  headerTextClassName,
  myHourLogsHash,
  wbsTreeHash
} from "../data";
// Features
// Components/Containers
import { FeatureProps } from "containers/ConnectedApp";
import WorkLog from "containers/WorkLog";
import DataTable from "containers/DataTable";
import { Tab, TabContents, TabSection } from "components/TabSection";
import WbsTree from "components/WbsTree";
import Separator from "components/Separator";
import { Client } from "@microsoft/microsoft-graph-client";

// Component for rendering individual tabs
const CustomTab = ({ href, label, index, tab, setTab }) => (
  <Tab
    href={href}
    index={index}
    tab={tab}
    setTab={() => setTab(index)}
  >
    {label}
  </Tab>
);

interface DashboardProps extends FeatureProps {
  client: Client,
  hash: string,
  reloadData: () => void
}

const Dashboard: React.FC<DashboardProps> = ({ client, hash, reloadData, resourceGroup, user, workItems, wbs }) => {
  const [tab, setTab] = React.useState(0); // Type inference for useState
  const [requireConfirmation, setRequireConfirmation] = React.useState(false);

  // Check URI Hashcode
  React.useEffect(() => {
    const newTab = dashboardHashCodes.indexOf(hash);
    if (newTab !== -1 && tab !== newTab) {
      setTab(newTab);
    }
  }, [hash]);

  // Confirmation Handlers
  const customSetTab = (newTab: number) => {
    if (requireConfirmation && confirm(getUnsavedChangesMessage("change tabs"))) {
      setRequireConfirmation(false);
      setTab(newTab);
    } else if (!requireConfirmation) {
      setTab(newTab);
    }
  };

  // Render
  return (
    <div>
      <h1 className={`font-bold text-primary ${headerTextClassName}`}>{dashboardHeader}</h1>
      <Separator />
      <div className={`mt-4 ${baseTextClassName}`}>
        <TabSection>
          <CustomTab href={createHourLogHash} label="Create Hour Log" index={dashboardHashCodes.indexOf(createHourLogHash)} tab={tab} setTab={customSetTab} />
          <CustomTab href={myHourLogsHash} label="My Hour Logs" index={dashboardHashCodes.indexOf(myHourLogsHash)} tab={tab} setTab={customSetTab} />
          <CustomTab href={wbsTreeHash} label="WBS Trees" index={dashboardHashCodes.indexOf(wbsTreeHash)} tab={tab} setTab={customSetTab} />
        </TabSection>
        <TabContents index={dashboardHashCodes.indexOf(createHourLogHash)} tab={tab}>
          <WorkLog {...{ client, reloadData, resourceGroup, setRequireConfirmation, user, wbs }} />
        </TabContents>
        <TabContents index={dashboardHashCodes.indexOf(myHourLogsHash)} tab={tab}>
          <DataTable {...{ client, reloadData, resourceGroup, setRequireConfirmation, user, workItems, wbs }} />
        </TabContents>
        <TabContents index={dashboardHashCodes.indexOf(wbsTreeHash)} tab={tab}>
          <WbsTree wbs={wbs} />
        </TabContents>
      </div>
    </div>
  );
};

export default Dashboard;
