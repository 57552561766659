import formatDate from "utils/formatDate";
import { WorkItemFormDataType } from ".";

const getInitialWorkItemFormState = (): WorkItemFormDataType => {
    return {
        wbsElementLookUpId: "",
        projectWbsListId: "",
        hoursWorked: 0.00
    }
}

export default getInitialWorkItemFormState;