import * as React from "react";
import IChildrenProps from "interfaces/IChildrenProps";
import { baseTextClassName, headerTextClassName } from "./data";
import Separator from "./components/Separator";

export interface ErrorBoundaryProps extends IChildrenProps { }

export interface ErrorBoundaryState {
    hasError: boolean,
    error: Error
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public state: ErrorBoundaryState = {
        hasError: false,
        error: null
    }

    public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true, error: error }
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("Uncaught Error", error, errorInfo)
    }

    public render() {
        if (this.state.hasError) {
            return <div className="m-4">
                <h1 className={headerTextClassName}>Sorry! Something went wrong.</h1>
                <Separator />
                <div className={baseTextClassName}>
                    An uncaught error has occurred: {this.state.error.message}
                </div>
            </div>
        }

        return this.props.children
    }
}

export default ErrorBoundary;