import { TableEntriesData, WorkItemChangesDictType, WorkItemChangesType } from "containers/DataTable";
import { WbsDataType, getWbsDataByList } from "features/WBS";
import { WorkItemDataType } from "features/WorkItem";
import calendarDiffrentialToDate from "utils/calendarDifferentialToDate";

// Get Work Item Day Index
// const getWorkItemDayIndex = (workItem) => new Date(workItem.date).getDay() - new Date(getFirstDate()).getDay();
const getWorkItemDayIndex = (workItem, calDiff) => new Date(workItem.date).getDay() - calendarDiffrentialToDate(calDiff).getDay();
// Get Hour Log Data from Work Item
const getHourLogDataFromWorkItem = (workItem: WorkItemDataType, wbs: WbsDataType[]): { element: string, hoursWorked: number } => {
  const wbsData = getWbsDataByList(workItem, wbs)
  if (wbsData) {
    return {
      element: wbsData.wbsLookUpTable[workItem.wbsElementLookUpId].element,
      hoursWorked: workItem.hoursWorked
    }
  }
  return {
    element: null,
    hoursWorked: 0
  }
}

function transformWorkItemData(currentWorkItems: WorkItemDataType[], workItemChanges: WorkItemChangesDictType, calendarDifferential: number, wbs: WbsDataType[], setHourLogData: React.Dispatch<React.SetStateAction<TableEntriesData>>) {
  // Check if Current Work Items is set to null
  if (currentWorkItems === null) {
    return
  }
  // Create Results Object
  const results: { [element: string]: { hoursByDay: number[], totalHours: number, colChanged: boolean[], rowChanged: boolean } } = {};
  // Format current Work Items data
  currentWorkItems.forEach((workItem, index) => {
    // Determine which day of the week it currently is by getting the index of that day
    const dayIndex = getWorkItemDayIndex(workItem, calendarDifferential)
    // Look-up WBS Element Name
    // Find WBS entry with matching WBS List ID and use lookup table to find WBS Element
    let { element, hoursWorked } = getHourLogDataFromWorkItem(workItem, wbs)
    if (element !== null) {
      // If no data row currently exists, create one
      if (!results[element]) {
        results[element] = {
          hoursByDay: new Array(7).fill(0),
          totalHours: 0,
          colChanged: new Array(7).fill(false),
          rowChanged: false
        };
      }
      // Add work item hours to data row
      results[element].hoursByDay[dayIndex] = hoursWorked;
      results[element].totalHours += hoursWorked;
      // Check if Work Items is not null
      if (workItemChanges !== null) {
        // Check if row has changes.
        if (workItemChanges.hasOwnProperty(index)) {
          results[element].rowChanged = true
        }
        // Check if columns have changes
        Object.keys(workItemChanges).map(workItemIndex => {
          // Get Item
          let changes = workItemChanges[workItemIndex] as WorkItemChangesType
          let changedWorkItem = currentWorkItems[workItemIndex] as WorkItemDataType
          let changedDayIndex = getWorkItemDayIndex(changedWorkItem, calendarDifferential)
          // Set flags
          if (changes.hoursWorked) {
            results[element].colChanged[changedDayIndex] = true
          }
        })
      }
    }
  });
  // Convert results to the final format
  setHourLogData(Object.entries(results).map(([element, data]) => ({
    element,
    hoursByDay: data.hoursByDay,
    totalHours: data.totalHours,
    rowChanged: data.rowChanged,
    colChanged: data.colChanged
  })))
}

export default transformWorkItemData;