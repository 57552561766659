import * as React from "react"
import { TreeNode } from "features/WBS";
import IChildrenProps from "interfaces/IChildrenProps";
import { CaretDown, CaretRight, CircleCheckIcon, ValueIcon } from "components/svgs";

export interface WbsFormElement {
  className?: string,
  disabled?: boolean,
  index: number,
  node: TreeNode,
  setWbsElement: (id: string) => void,
  selectedLookUpId: string
}

const WbsFormElement: React.FC<WbsFormElement> = ({ className = "", disabled = false, index, node, setWbsElement, selectedLookUpId }) => {
  // Local State
  const [open, setOpen] = React.useState(false)
  // On Click Handler
  const onClickSelect = (event) => {
    if (!disabled) {
      if (node.uuid !== selectedLookUpId) {
        setWbsElement(node.uuid)
      } else {
        setWbsElement("")
      }
    }
  }
  const onClickOpen = (event) => {
    if (!disabled) {
      setOpen(!open)
    }
  }
  const onClick = node.title === "wp" ? onClickSelect : onClickOpen
  // Icon
  const selectableIcon = (selectedLookUpId === node.uuid ? <CircleCheckIcon /> : <ValueIcon />)
  const icon = node.title === "wp" ? selectableIcon : ""
  const textColourClassName = (selectedLookUpId === node.uuid ? "text-primary font-bold" : "")
  // Render
  return (
    <div>
      <ul className="ml-4 my-2 w-full">
        <li onClick={onClick} className={`hover:bg-gray-100 w-full cursor-pointer ${node.title === "wp" ? "flex items-center": ""} ${textColourClassName}`}>
          {icon} <span className="ml-2">{node.name}</span>
        </li>
        {open && node.children.map((child, inner_index) => (
          <li>
            <WbsFormElement
            key={`wbs-element-${index}-${inner_index}`}
            disabled={disabled}
            index={index+1}
            node={child}
            setWbsElement={setWbsElement}
            selectedLookUpId={selectedLookUpId}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default WbsFormElement;