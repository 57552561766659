import * as React from "react"
import { wbsTreeTitle } from "../data";
// Components
import { Table, TableHeader } from "components/Table";
import WbsElement from "components/WbsElement";
import { createTree, TreeNode, WbsDataType } from "../features/WBS";

interface WbsTreeProps {
    wbs: Array<WbsDataType>
}

const WbsTree: React.FC<WbsTreeProps> = ({ wbs }) => <div>
    {wbs.map((project, index) => {
        let wbsRoot: TreeNode = createTree(project.wbsEls)
        return <div className="mb-4" key={`wbs-tree-${index};${project.projectName}`}>
            <Table>
                <TableHeader>{wbsTreeTitle}</TableHeader>
            </Table>
            <ul>
                <WbsElement node={wbsRoot} />
            </ul>
        </div>
    })}
</div>

export default WbsTree;