import * as React from "react";
import { Client } from "@microsoft/microsoft-graph-client";
import formatDate from "utils/formatDate";
import {
  ResourceGroupType,
  addWorkItemBtnLabel,
  hourLogFormTitle,
} from "../data";
import { AppDispatch, useAppDispatch } from "app/store";
import {
  WorkLogFormDataType,
  getWorkLogFormState,
  getInitialWorkItemFormState,
  WorkItemFormDataType,
} from "features/WorkItem";
import { WbsDataType } from "features/WBS";
import { UserDataType } from "features/User";
import ValidationResult from "interfaces/ValidationResult";
import Button, { MiniButton } from "components/Button";
import ButtonGroup from "components/ButtonGroup";
import {
  FormAlert,
  FormWrapper,
  SaveButtonLabel,
  CancelButtonLabel,
  FormDiv,
  FormFieldWrapper,
  FormLabel,
  FormInput,
  WorkItemField,
} from "components/Form";
import { Table, TableHeader } from "components/Table";
import LoadingPage from "components/LoadingPage";
import validate from "features/WorkItem/validateWorkItem";
import submit from "features/WorkItem/submitWorkItem";


const getMaxDate = (): string => {
  const today = new Date();
  return formatDate(today);
};

export interface WorkLogProps {
  client: Client;
  reloadData: () => void;
  resourceGroup: ResourceGroupType;
  setRequireConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserDataType;
  wbs: Array<WbsDataType>;
}

const WorkLog: React.FC<WorkLogProps> = ({
  client,
  reloadData,
  resourceGroup,
  setRequireConfirmation,
  user,
  wbs,
}) => {
  // Hooks
  const dispatch: AppDispatch = useAppDispatch();

  // Local State
  const [formData, setFormData] = React.useState(getWorkLogFormState());
  const [processing, setProcessing] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");

  // This function is used to set the error state and alert message.
  const setErrorObject = (bool: boolean, msg: string) => {
    setAlertMsg(msg);
    setError(bool);
  };

  // This function resets the error state and alert message.
  const resetError = () => setErrorObject(false, "");

  // This function triggers the validation process and sets the error state.
  const performValidation = () => {
    resetError();
    const { error, message } = validate(formData, resourceGroup);
    setErrorObject(error, message);
    return !error;
  };

  // API Calls
  const processResult = (result: ValidationResult) => {
    if (result.error && result.message === "SYS") {
        const errorMsg = "Something went wrong! Please refresh the page.";
        setAlertMsg(errorMsg);
        setError(true);
        setFailure(true);
        setProcessing(false);
    } else if (result.error) {
        // Reset the form, and construct a comprehensive error message
        setFormData(getWorkLogFormState());
        setAlertMsg(result.message);
        setError(true);
        setProcessing(false);
    } else {
      // If work items were created successfully, reset the form and show a success message
      setFormData(getWorkLogFormState());
      setAlertMsg(result.message);
      setSuccess(true);
      reloadData();
    }
  }

  // Event Handlers
  const onClickSave = (event: React.MouseEvent) => {
    setRequireConfirmation(false);
    setProcessing(true);

    if (performValidation()) {
      submit(client, formData, resourceGroup, user)
        .then(res => {
            processResult(res)
        })
        .catch(error => {
            console.error(error);
            let res = {
                error: true,
                message: error
            }
            processResult(res)
        })
    } else {
      setProcessing(false);
    }
  };

  const onClickCancel = (event: React.MouseEvent) => {
    setRequireConfirmation(false);
    setFormData(getWorkLogFormState());
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequireConfirmation(true);
    const { name, value } = event.target;
    handleChange(name, value);
  };

  // Function to handle changes in form fields, including work item fields
  const handleChange = (name: string, value: string | WorkLogFormDataType, index: number | null = null) => {
    // Clone the existing form data
    const updatedFormData = { ...formData };
  
    // Check if the change is related to a specific work item
    if (index !== null) {
      // Clone the array of form data
      const updatedWorkItems: Array<WorkLogFormDataType> = [...formData[name]];
  
      // Update the values of the specified work item field at the provided index
      updatedWorkItems[index] = value as WorkLogFormDataType;
  
      // Update the form data with the modified array of work items
      updatedFormData[name] = updatedWorkItems;
    } else {
      // Update the value of the non-work item field
      updatedFormData[name] = value;
    }
  
    // Update the overall form data with the changes
    setFormData(updatedFormData);
  };

  // Work Item Event Handlers
  // Function to add a new work item to the form
  const onAddWorkItem = (event: React.MouseEvent) => {
    // Clone the existing array of work items and add a new work item
    const updatedWorkItems = [...formData.workItems, getInitialWorkItemFormState()];
  
    // Update the form data with the new array of work items
    setFormData({
      ...formData,
      workItems: updatedWorkItems,
    });
  };

  // Function to remove a work item from the form
  const onRemoveWorkItem = (index: number) => {
    // Check if there are multiple work items
    if (formData.workItems.length > 1) {
      // Clone the existing array of work items
      const updatedWorkItems = [...formData.workItems];
  
      // Remove the specified work item using the provided index
      updatedWorkItems.splice(index, 1);
  
      // Update the form data with the modified array of work items
      setFormData({
        ...formData,
        workItems: updatedWorkItems,
      });
    } else {
      // If there's only one work item, reset the form with a single empty work item
      setFormData({
        ...formData,
        workItems: [getInitialWorkItemFormState()],
      });
    }
  };

  // Function to handle changes in a specific work item within the form
  const onWorkItemChange = (data: WorkItemFormDataType, index: number) => {
    // Clone the existing array of work items
    const updatedWorkItems = [...formData.workItems];
  
    // Update the data of the specified work item at the provided index
    updatedWorkItems[index] = data;
  
    // Update the form data with the modified array of work items
    setFormData({
      ...formData,
      workItems: updatedWorkItems,
    });
  };

  // Handle .CSV and .XSLV Uploads
  const onSpreadSheetUpload = () => {

  }

  // Render
  return processing ? (
    <LoadingPage className="mt-4 md:mt-8 lg:mt-12" />
  ) : (
    <div>
      <Table>
        <TableHeader>{hourLogFormTitle}</TableHeader>
      </Table>
      <FormWrapper>
        <FormDiv>
          <FormAlert success={success} error={error} msg={alertMsg} />
          <div className="md:grid md:grid-cols-1 lg:grid-cols-2 md:gap-2 xl:grid-cols-3 lg:gap-4">
            <div>
              <FormFieldWrapper>
                <FormLabel label={"Date"} name={"date"} />
                <FormInput
                  name={"date"}
                  onChange={onChange}
                  value={formData.date}
                  type={"date"}
                  disabled={failure}
                  min={"2022-01-01"}
                  max={getMaxDate()}
                />
              </FormFieldWrapper>
              <FormFieldWrapper className="flex items-center content-center">
                <FormLabel
                  name={"workItems[]"}
                  label={`Work Items: ${formData.workItems.length}`}
                />
                <MiniButton
                  align={"right"}
                  variant={"primary"}
                  onClick={onAddWorkItem}
                >
                  {addWorkItemBtnLabel}
                </MiniButton>
              </FormFieldWrapper>
              <ButtonGroup className="mt-4">
                <React.Fragment>
                  <Button
                    disabled={failure}
                    variant={"confirm"}
                    onClick={onClickSave}
                  >
                    <SaveButtonLabel />
                  </Button>
                  <Button
                    disabled={failure}
                    variant={"cancel"}
                    onClick={onClickCancel}
                  >
                    <CancelButtonLabel />
                  </Button>
                </React.Fragment>
              </ButtonGroup>
            </div>
            {formData.workItems.map((workItem, index) => (
              <React.Fragment key={`work-item-section-${index}`}>
                <WorkItemField
                  disabled={failure}
                  index={index}
                  label={`Work Item ${index + 1}`}
                  name={`workItems[${index}]`}
                  onChange={(data) => onWorkItemChange(data, index)}
                  onRemove={() => onRemoveWorkItem(index)}
                  wbs={wbs}
                  values={workItem}
                />
              </React.Fragment>
            ))}
          </div>
        </FormDiv>
      </FormWrapper>
    </div>
  );
};

export default WorkLog;