import ISODateString from "interfaces/ISODateString";

// Text Values
export const companyName = "Turnkey";
export const dashboardHeader = "Turnkey HourLogger";
export const defaultErrorMessage = "Something went wrong!"

export const hourLogFormTitle = "Create New Hour Log";
export const addWorkItemBtnLabel = "+";
export const removeWorkItemBtnLabel = "x";

export const wbsTreeTitle = "WBS Tree";

export const getUnsavedChangesMessage = (action: string): string => `There are unsaved changes. Are you sure you would like to ${action} without saving? All your unsaved changes will be lost.`

// Hash Codes
export const myHourLogsHash = "#my-hour-logs"
export const createHourLogHash = "#create-hour-log"
export const wbsTreeHash = "#wbs-tree"

export const dashboardHashCodes: Array<string> = [
    createHourLogHash,
    myHourLogsHash,
    wbsTreeHash
]

// Classes
export const headerTextClassName = "text-lg md:text-xl lg:text-2xl";
export const baseTextClassName = "text-sm md:text-base lg:text-xl";

// API Values
// Sites
export const sitesResource = "/site?search=*";
// User(s)
export const userResource = "/me?$select=displayName,id,mail";
export const userMemberOfResource = "/me/memberOf?$select=id";
// Resource Groups
export type ProjectEndpointsType = {
    siteList: string,
    wbsElementList: string,
    workItemGet: (id: number) => string,
    workItemList: string,
    workItemCreate: string,
    workItemUpdate: string,
    workItemDelete: string
}

export type ProjectsType = {
    [wbsListId: string]: {
        projectName: string,
        startDate: ISODateString,
        siteId: string // Some Projects may have a different Site ID then the the administrative Site where they are tracked
    }
}

export type ResourceGroupType = {
    groupId: string,
    groupDisplayName: string,
    workItemSiteId: string,
    workItemListId: string,
    projects: ProjectsType
}

export const siteList = (siteId: string): string => `/sites/${siteId}/lists`
export const wbsElementList = (siteId: string, listId: string): string => `/sites/${siteId}/lists/${listId}/items?$expand=fields`
// Returns a function which takes the string param id and creates the GET url for a work item
export const workItemGet = (siteId: string, listId: string, itemId: number): string => `/sites/${siteId}/lists/${listId}/items/${itemId}?$expand=fields`
export const workItemList = (siteId: string, listId: string, userId: string): string => `/sites/${siteId}/lists/${listId}/items?$expand=fields&filter=fields/UserId eq '${userId}'`
export const workItemCreate = (siteId: string, listId: string): string => `/sites/${siteId}/lists/${listId}/items`
export const workItemUpdate = (siteId: string, listId: string, itemId: string): string => `/sites/${siteId}/lists/${listId}/items/${itemId}/fields`
export const workItemDelete = (siteId: string, listId: string, itemId: number): string => `/sites/${siteId}/lists/${listId}/items/${itemId}`
// Employee Resource Group
export const analyticsSiteId = "turnkeyaquaponics.sharepoint.com,0c59932f-a94a-4e97-bd8a-6713aa7eda38,cb48c523-b28e-4473-98ad-53927cc34280";
export const workItemsListId = "71fb2ed0-1071-48c3-8ccd-455981fbecdb";
export const tk22ElementsListId = "ec270b0c-f3aa-41e4-9e90-9c5d6770fd01";
export const eeceafAdminSiteId = "turnkeyaquaponics.sharepoint.com,76526bfd-2d31-471c-8529-d196ac0aa051,fddf3360-86bb-449d-8233-138161530210"
// export const hicWbsElementsListId = "2bd84702-de6d-42bf-8e42-f346f188abee";
export const hicWbsElementsListId = "5bc19351-7e44-4b89-86ab-03df6793e953";

export const employeeResourceGroup: ResourceGroupType = {
    groupId: "4fe1e214-ee1f-46bb-8dd2-19de43488eff",
    groupDisplayName: "EE-CEAF Admin",
    workItemSiteId: analyticsSiteId,
    workItemListId: workItemsListId,
    projects: {
        [hicWbsElementsListId]: {
            projectName: "Homegrown Innovation Challenge",
            startDate: "2023-07-01",
            siteId: eeceafAdminSiteId
        }, 
        [tk22ElementsListId]: {
            projectName: "Turnkey 2022",
            startDate: "2022-01-01",
            siteId: analyticsSiteId
        }/*,
        ["PastProjectListId"]: {
            projectName: "Turnkey 2022",
            startDate: "2022-01-01",
            siteId: "TBD"
        }*/
    }
}

// Contributor Resource Group
export const contributorResourceGroup = {
    groupId: "ed10d93e-503b-4746-8e33-20182c90e73f",
    groupDisplayName: "EE-CEAF"
}