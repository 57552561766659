import * as React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
// Data
import { store } from './app/store';
// Containers/Components
import ConnectedApp from './containers/ConnectedApp';
import NavBar from './components/NavBar';
import ErrorBoundary from './ErrorBoundary';
// import { PublicClientApplication } from '@azure/msal-browser';

export const AuthProvider = new Msal2Provider({
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`
})
Providers.globalProvider = AuthProvider;

const TurnkeyHourLogger: React.FC = () => {
    const [loginInitated, setLoginInitated] = React.useState(false);
    const [loginComplete, setLoginComplete] = React.useState(false);
    const [loginFailed, setLoginFailed] = React.useState(false);

    return <React.StrictMode>
        <ErrorBoundary>
            <Provider store={store}>
                <BrowserRouter>
                    <div>
                        <NavBar
                            setLoginInitiated={setLoginInitated}
                            setLoginComplete={setLoginComplete}
                            setLoginFailed={setLoginFailed} />
                        <div className="h-[calc(100vh-92px)] overflow-y-scroll overflow-x-hidden">
                            <div className="m-16">
                                <Routes>
                
                                    <Route path="/" element={<ConnectedApp
                                        loginInitiated={loginInitated}
                                        loginComplete={loginComplete}
                                        loginFailed={loginFailed} />} />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    </React.StrictMode>
}


const root = createRoot(document.getElementById('root'));
root.render(<TurnkeyHourLogger />)