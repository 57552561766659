import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
// Features
import { userReducer } from "features/User";
import { workItemReducer } from 'features/WorkItem';
import { wbsReducer } from 'features/WBS'

export const store = configureStore({
    reducer: {
        user: userReducer,
        workItems: workItemReducer,
        wbs: wbsReducer
    }
})

// Infer the 'RootState' and 'AppDispatch' types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {reducerName: reducerNameState}
export type AppDispatch = typeof store.dispatch;

// Use Throughout App instead of plain 'useDispatch' and 'useSelector'
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;