import * as React from "react";
import '../styles/app.css';
// Data/Utilites
import { Client, ClientOptions } from "@microsoft/microsoft-graph-client";
import { Get, IProviderAccount, Providers } from "@microsoft/mgt-react";
import { connect } from "react-redux";
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from "app/store";
import { ResourceGroupType, employeeResourceGroup, userMemberOfResource, userResource, wbsElementList, workItemList } from "../data";
import getActiveAccount from "utils/getActiveAccount";
import isDataLoaded from "utils/isDataLoaded";
// Features
import { GroupResponseType, UserDataType, loadResourceGroup, loadUser } from "features/User";
import { WorkItemDataType, WorkItemResponseDataType, getWorkItemDataFromResponse, loadWorkItems } from "features/WorkItem";
import { WbsElementDataType, WbsLookUpTableType, WbsResponseDataType, WbsDataType } from "features/WBS";
// Components/Containers
import Dashboard from "containers/Dashboard";
import LoadingPage from "components/LoadingPage";
import SignInMessage from "components/SignInMessage";
import { useLocation } from "react-router-dom";
import { loadWbs } from "../features/WBS";
import isDataUnloaded from "../utils/isDataUnloaded";

// Used in API calls below to reduce code duplication, should be relocated.
const errorCallback = error => {
  throw new Error(error.message)
}

export interface FeatureProps {
  user: UserDataType,
  resourceGroup: ResourceGroupType,
  workItems: Array<WorkItemDataType>,
  wbs: Array<WbsDataType>
}

export interface ConnectedAppProps extends FeatureProps {
  loginInitiated: boolean,
  loginComplete: boolean,
  loginFailed: boolean
}

const ConnectedApp: React.FC<ConnectedAppProps> = ({ loginInitiated, loginComplete, loginFailed, resourceGroup, user, workItems, wbs }) => {
  // Hooks
  const dispatch: AppDispatch = useAppDispatch();
  const { hash } = useLocation();
  /*
    * Sign-In & Loading
    */
  const [signInComplete, setSignInComplete] = React.useState(false);
  const [loadingComplete, setLoadingComplete] = React.useState(false);
  const [loadLock, setLoadLock] = React.useState(false);
  // Get Active Account or Null
  const account: IProviderAccount | null = getActiveAccount()
  // Flag for sign-in
  React.useEffect(() => {
      setSignInComplete((loginComplete || account !== null))
  }, [
      account,
      loginComplete
  ])
  // Flag for loading status
  const loadingDependencies = [
      resourceGroup,
      user,
      workItems,
      wbs
  ]
  // Set loading complete when all resources are loaded
  React.useEffect(() => {
      let loaded = isDataLoaded({ resourceGroup, user, workItems, wbs })
      setLoadingComplete(loaded)
  }, loadingDependencies)
  // Lock loading after the load is complete, unlocking loading if loading is reset.
  React.useEffect(() => setLoadLock(loadingComplete), [loadingComplete])
  // Unlock loading for reload when all data is reset
  React.useEffect(() => {
      if (isDataUnloaded({ resourceGroup, user, workItems, wbs })) {
          setLoadLock(false)
      }
  }, loadingDependencies)
  // Reload Data
  const reloadData = () => {
      dispatch(loadUser(null))
      dispatch(loadResourceGroup(null))
      dispatch(loadWorkItems(null))
      dispatch(loadWbs(null))
  }
  /*
    * API Client
    */
  let clientOptions: ClientOptions = {
      authProvider: Providers.globalProvider
  }
  const client = Client.initWithMiddleware(clientOptions)
  /*
    * API Calls
    */
  // Get Sharepoint Sites that the User is a member of from Graph API
  React.useEffect(() => {
    if (!signInComplete || loadLock) return
    client.api(userMemberOfResource).get()
    .then(result => {
      let dataContext = result as GroupResponseType

      const isEmployee = dataContext.value.some(g => g.id == employeeResourceGroup.groupId);
      var resourceGroup: ResourceGroupType = isEmployee ? employeeResourceGroup : null;
      if (resourceGroup === null) {
        // TODO
        alert("Invalid Permissions for Accessing this Application");
        return
      }
      dispatch(loadResourceGroup(resourceGroup))
    })
    .catch(errorCallback)
  }, [loadLock, signInComplete])
  // Get User Information from from Graph API
  React.useEffect(() => {
    if (!signInComplete || loadLock) return
    client.api(userResource).get()
    .then(dataContext => {
      // Load User
      dispatch(loadUser({
        id: dataContext.id,
        displayName: dataContext.displayName,
        email: dataContext.mail
      }))
    })
    .catch(errorCallback)
  }, [loadLock, signInComplete])
  // Get Work Items and WBS Elements
  React.useEffect(() => {
    if (user === null || resourceGroup === null || loadLock) return
    // Get Work Items
    Object.entries(resourceGroup.projects).map(([wbsListId, { siteId, projectName }]) => {
      client.api(workItemList(resourceGroup.workItemSiteId, resourceGroup.workItemListId, user.id)).header('Prefer', 'HonorNonIndexedQueriesWarningMayFailRandomly').get()
        .then(dataContext => {
          // Format Work Items
          var workItems: Array<WorkItemDataType> = []
          dataContext.value.map((workItem: WorkItemResponseDataType) => {
            const data = getWorkItemDataFromResponse(workItem, projectName, resourceGroup.workItemListId)
            if (workItems) {
              workItems.push(data);
            }
          })
          // Load Work Items
          dispatch(loadWorkItems(workItems))
        })
        .catch(errorCallback)

        client.api(wbsElementList(siteId, wbsListId)).get()
        .then(dataContext => {
          // Format WBS Elements
          var wbsEls: Array<WbsElementDataType> = []
          var wbsLookUpTable: WbsLookUpTableType = {}
          dataContext.value.map((wbsEl: WbsResponseDataType, index) => {
            const fields = wbsEl.fields
            wbsEls.push({
              uuid: fields.UUID,
              title: fields.Title,
              element: fields.field_1
            })
            wbsLookUpTable[fields.UUID] = {
              uuid: fields.UUID,
              title: fields.Title,
              element: fields.field_1
            }
          })
          // Load WBS LookUp Table and Elements with Project Name
          dispatch(loadWbs({
            projectName: projectName,
            listId: wbsListId,
            wbsLookUpTable: wbsLookUpTable,
            wbsEls: wbsEls
          }))
        })
        .catch(errorCallback)
      })
  }, [user, resourceGroup, loadLock])
  /*
    * Render
    */
  // Sign-in Message
  if (!signInComplete) {
      return <div>
          <SignInMessage loginInitiated={loginInitiated} loginFailed={loginFailed} />
      </div>
  }
  // Loading Page
  else if (!loadingComplete) {
      return <div>
          < LoadingPage />
      </div>
  }
  // Dashboard
  return <div>
      <Dashboard
          client={client}
          hash={hash}
          reloadData={reloadData}
          resourceGroup={resourceGroup}
          user={user}
          workItems={workItems}
          wbs={wbs}
      />
  </div>
}

const mapStateToProps = (state: RootState): FeatureProps => ({
  resourceGroup: state.user.resourceGroup,
  user: state.user.values,
  workItems: state.workItems.list,
  wbs: state.wbs.list
})

export default connect(mapStateToProps, {})(ConnectedApp)

/*
* Data Provider
*/
interface DataProviderProps {
  client: Client,
  dispatch: AppDispatch
}

const DataProvider: React.FC<DataProviderProps> = ({ client, dispatch }) => {
  // Hooks
  const resourceGroup = useAppSelector(state => state.user.resourceGroup)
  const user = useAppSelector(state => state.user.values)

  return null
}