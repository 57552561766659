import formatDate from "utils/formatDate";
import { WorkLogFormDataType, getInitialWorkItemFormState } from ".";

const getWorkLogFormState = (): WorkLogFormDataType => {
    return {
        date: formatDate(new Date),
        workItems: [getInitialWorkItemFormState()]
    }
}

export default getWorkLogFormState;