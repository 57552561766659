import * as React from "react";

import { TableHeadItem, TableRow } from "components/Table"
import formatDate from "utils/formatDate";

const getDateHeaderRows = (calendarDifferential: number, setDateHeaderRows: React.Dispatch<React.SetStateAction<React.ReactNode>>) => {
    const dateHeaders = []
    for (var i = calendarDifferential; i <= calendarDifferential + 6; i++) {
        let date = new Date
        let day = new Date(date.setDate(i))
        let formattedDate = formatDate(day)
        dateHeaders.push(<TableHeadItem key={`date-header-${formattedDate}`}>{formattedDate}</TableHeadItem>)
    }
    setDateHeaderRows(<TableRow className={`text-sm`} key={'hour-log-date-headers'}>
        <TableHeadItem>WBS Element</TableHeadItem>
        {dateHeaders}
        <TableHeadItem>Total Hours Worked</TableHeadItem>
    </TableRow>)
}

export default getDateHeaderRows;