import * as React from "react";
import IChildrenProps from "interfaces/IChildrenProps";

interface Props extends IChildrenProps {
    className?: string
}

const ButtonGroup: React.FC<Props> = ({ children, className = "" }) => {
    var classes = `inline-flex w-full space-x-2 mb-4 ${className}`
    return <div className={classes}>
        {children}
    </div>
}

export default ButtonGroup;